import ArendaService from '@/api/arenda-service'

const state = {
    arendaid: '',
    doc: {},
    task: {},
    dataLoaded: false,
    command: '',
    isError: false,
    ErrorText: '',
    menu_visible: false,
    saving: false,
    saved: false,
    url_vars: {},
    cars: [],
    tg_name: '',
    tg_id: '',
    tg: {},
    requests: [],
    current_request_id: '',
    current_request: {},
    innerDocs: [],
    current_doc_id: '',
    current_doc: {},
    docInSaved: false,
    docOutSaved: false,
    discountsSaved: false,
    rkoSaved: false,
    pkoSaved: false,
    taskSaved: false,
    taskid: '',
    taskList: [],
    
}
const getters = {
  doc: state =>{
    return state.doc
  },
  task: state =>{
    return state.task
  },
  taskid: state =>{
    return state.taskid
  },
  arendaid: state =>{
    return state.arendaid
  },
  dataLoaded: state =>{
    return state.dataLoaded
  },
  saving: state =>{
    return state.saving
  },
  docInSaved: state =>{
    return state.docInSaved
  },
  docOutSaved: state =>{
    return state.docOutSaved
  },
  rkoSaved: state =>{
    return state.rkoSaved
  },
  pkoSaved: state =>{
    return state.pkoSaved
  },
  taskSaved: state =>{
    return state.taskSaved
  },
  discountsSaved: state =>{
    return state.discountsSaved
  },
  command: state =>{
    return state.command
  },
  cars: state =>{
    return state.cars
  },
  requests: state =>{
    return state.requests
  },   
  url_vars: state =>{
    return state.url_vars
  },
  current_request: state =>{
    return state.current_request
  },
  current_request_id: state =>{
    return state.current_request_id
  },
  innerDocs: state =>{
    return state.innerDocs
  },   
  current_doc: state =>{
    return state.current_doc
  },
  current_doc_id: state =>{
    return state.current_doc_id
  },
  taskList: state =>{
    return state.taskList
  },    
  tg: state =>{
    return state.tg
  },  
}
const mutations = {
  setDoc (state, value) {
    state.doc = value
  },
  dataLoaded (state, value) {
    state.dataLoaded = value;
  },
  setArendaid (state, value) {
    state.arendaid = value
  },
  setMode (state, value) {
    state.command = value
  },
  setError(state, value) {

    state.ErrorText = value 
    state.isError = (value != '') 
  },
  setUrlvars(state, value) {
    state.url_vars =  value  
  },
  setSaved(state, value) {
    state.saved = value
    state.saving = !value
  },
  setdocInSaved(state, value) {
    state.docInSaved = value
  },
  setdocOutSaved(state, value) {
    state.docOutSaved = value
  },
  setrkoSaved(state, value) {
    state.rkoSaved = value
  },
  setpkoSaved(state, value) {
    state.pkoSaved = value
  },
  settaskSaved(state, value) {
    state.taskSaved = value
  },
  setDiscountsSaved(state, value) {
    state.discountsSaved = value
  },
  setSaving(state, value) {
    state.saving = value
  },
  setShowMenu(state, value) {
    state.menu_visible = value
  },
  setCars (state, value) {
    state.cars = value
  },
  setRequests (state, value) {
    state.requests = value
  }, 
  setTGName(state, value) {
    state.tg_name = value
  },
  setTGid(state, value) {
    state.tg_id = value
  },
  setTG(state, value) {
    //value = this.TWA.initDataUnsafe
    state.tg = {user: value.user, 
                userid: value.user.id,
                username: value.user.username,
              }
    state.tg_id = state.tg.id
    state.tg_name = state.tg.name
  },
  setCurrentRequest(state, value) {
    Object.assign(state.current_request, value)
    state.current_request_id = value.id
  },
  setCurrentRequestID(state, value) {
    state.current_request_id = value
    if (value=='')
      state.current_request = {}
  },  
  setInnerDocs (state, value) {
    state.innerDocs = value
  }, 
  setCurrentDoc(state, value) {
    Object.assign(state.current_doc, value)
    state.current_doc_id = value.id
  },
  setCurrentDocID(state, value) {
    state.current_doc_id = value
    if (value=='')
      state.current_doc = {}
    else 
      state.current_doc =  state.innerDocs.find(o => o.id === value)
  },  
  setTaskid (state, value) {
    state.taskid = value
    if (value=='')
      state.task = {}
    else 
      state.task =  state.taskList.find(o => o.id === value)
  },
  setTask (state, value) {
    state.task = value
    state.taskid = value.id
  },  
  setTaskList (state, value) {
    state.taskList = value
  }, 
}

const actions = {
  async loadData({commit,state},params) {
      console.log('store doc loadData',JSON.stringify(params))
      commit('setArendaid',params.arendaid) 
      const payload = {user: state.tg_name, userid: state.tg_id}
      const response = await ArendaService.docinfo(state.arendaid, payload)
      commit('setDoc',response) 
      commit('dataLoaded',true) 
  },
  async loadRequests({commit,state},params) {
    console.log('store load requests',JSON.stringify(params))
    const payload = {...params,...state.tg}
    const response = await ArendaService.getRequests('0',payload)
    console.log('requests loaded ')
    if (response.data.error){
      commit('setError', response.data.error)    
    } else
      commit('setRequests',response.data.requests)       
    commit('dataLoaded',true) 
  },  
  async loadRequest({commit,state},id) {
    console.log('store load request',id)
    const payload = {id: id,
                    ...state.tg}
    const response = await ArendaService.getRequests(id,payload)
    console.log('request loaded ')
    if (response.data.error){
      commit('setError', response.data.error)    
    } else {
      commit('setCurrentRequest',response.data.requests[0])       
    }
    commit('dataLoaded',true) 
  }, 

  async loadInnerDocs({commit,state},params) {
    console.log('store load inner docs',JSON.stringify(params))
    const payload = {...params,...state.tg}
    const response = await ArendaService.getInnerDocs('0',payload)
    console.log('inner docs loaded ')
    if (response.data.error){
      commit('setError', response.data.error)    
    } else
      commit('setInnerDocs',response.data.docs)       
    commit('dataLoaded',true) 
  },  

  async loadTaskList({commit,state},params) {
    console.log('store load task list',JSON.stringify(params))
    const payload = {...params,...state.tg}
    const response = await ArendaService.getTaskList('0',payload)
    console.log('task list loaded ', response.data.result)
    if (response.data.error){
      commit('setError', response.data.error)    
    } else
      commit('setTaskList',response.data.result)       
    commit('dataLoaded',true) 
  },  
  async saveArendaOut({commit,dispatch,state},params) {
    console.log('store doc saveArendaOut',JSON.stringify(params))
    //commit('dataLoaded',false) 
    commit('setSaving',true) 

    const response = await ArendaService.saveArendaOut(params.id, params)
    commit('setSaved',true) 
    await dispatch('loadData',{arendaid: state.arendaid})
    return response
  },
  async saveArendaIn({commit,dispatch,state},params) {
    console.log('store doc saveArendaIn',JSON.stringify(params))
    commit('setSaving',true) 

    const response = await ArendaService.saveArendaIn(params.id, params)
    commit('setSaved',true) 
    await dispatch('loadData',{arendaid: state.arendaid})
    return response
  },  
async savePKO({commit,dispatch,state},params) {
    console.log('store doc savePKO',JSON.stringify(params))
    commit('setSaving',true) 

    const response = await ArendaService.saveNewPKO(params.id, params)
    console.log('store doc savePKO saved',JSON.stringify(response))
  
    await dispatch('loadData',{arendaid: state.arendaid})
    commit('setSaved',true) 
    return response
  },
  async saveRKO({commit,dispatch,state},params) {
    console.log('store doc saveRKO',JSON.stringify(params))
    commit('setSaving',true) 

    const response = await ArendaService.saveNewRKO(params.id, params)
    console.log('store doc saveRKO saved',JSON.stringify(response))
  
    await dispatch('loadData',{arendaid: state.arendaid})
    commit('setSaved',true) 
    return response
  },
  async saveDiscounts({commit,dispatch,state},params) {
    console.log('store doc saveDiscounts',JSON.stringify(params))
    commit('setSaving',true) 

    const response = await ArendaService.saveDiscounts(params.id, params)
    console.log('store doc saveDiscounts saved',JSON.stringify(response))
  
    await dispatch('loadData',{arendaid: state.arendaid})
    commit('setSaved',true) 
    return response
  },  
  async loadCars({commit},params) {
    console.log('store loading Cars',JSON.stringify(params))

    const response = await ArendaService.loadCars()
   // console.log('store doc load Cars loaded',JSON.stringify(response))
  
    commit('setCars',response.result) 
    return response
  },
  async loadTask({commit,state},params) {
    console.log('store task loadTask',JSON.stringify(params))
    commit('setTaskid',params.taskid) 
    const payload = {user: state.tg_name, userid: state.tg_id}
    const response = await ArendaService.gettask(state.taskid, payload)
    commit('setTask',response) 
    commit('dataLoaded',true) 
},

}



const storeConf = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
export default storeConf
