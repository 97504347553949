function TaskModel(options) {
    let model = {
        id: options.id,
        docnum: options.docnum || '',
        tasknum: options.tasknum || '',
        ts: options.ts || '',
        task: options.task || '',
        sotr: options.sotr || '',
        document: options.document || '',
        description: options.description || '',
        damage: options.damage || '',
        arenda: options.arenda || '',
        action: options.action || '',
        type: options.type || '',
        status: options.status,
        date_start: '',
        date_end: '',
        city_name: options.city_name || '',
        city_id: options.city_id || -1,
        fields: options.fields || [],
        client_name: options.client_name,
        needrepair: options.needrepair || false,
        completed: options.completed || false,
    };
    return model;
}

export default TaskModel;