import Axios from 'axios';
import DocumentModel from './arenda-document-model'
import TaskModel from './arenda-task-model'
const RESOURCE_NAME = '/arenda2/hs/Cars';

//https://forproger.ru/article/osnovy-vuejs-http-klient-axios-i-peremennye-okruzheniya

export default {
    getAll() {
        return Axios.get(RESOURCE_NAME+'/CarList')
    },


    async docinfo(id,payload) {
        console.log('docinfo axios: '+JSON.stringify(payload))
       // const payload2 = {optype:'ppp'}
        var params = '?'
        var headers = {}
        if (payload && payload.operationtype) {
            params = params+'optype='+payload.operationtype
        }
        if (payload && payload.user) {
            headers.TG =  payload.user 
            params = params+'&usr='+payload.user
            headers.tgid =  payload.userid
        }
        const res = await Axios.get(RESOURCE_NAME+'/docinfo/'+id+params,{
            headers: headers}
          )
        console.log('res axios: '+JSON.stringify(res.data))
        let elem = new DocumentModel(res.data)
        if (res.data.result != true) {
            elem = {id:'', result: res.data.error}
        }
        return elem
    },

    async getReport(name,payload) {
        console.log('gerReport axios: '+name,JSON.stringify(payload))
       // const payload2 = {optype:'ppp'}
        var params = '?'
        var headers = {}
        if (payload && payload.user) {
            headers.TG =  payload.user 
            headers.tgid =  payload.userid
            params = params+'&usr='+payload.user
        }
        if (payload && payload.period) {
            params = params+'&period='+payload.period
        }
        if (payload && payload.city) {
            params = params+'&city='+payload.city
        }
        const res = await Axios.get(RESOURCE_NAME+'/getreport/'+name+params,{
            headers: headers}
          )
        console.log('res report axios: '+JSON.stringify(res))
        console.log('res axios: ',(res.data != ''))

        return res
    },
    async getRequests(id,payload) {
        console.log('getRequests axios: ',id,JSON.stringify(payload))
       // const payload2 = {optype:'ppp'}
        var params = '?'
        var headers = {}
        if (payload && payload.username) {
            headers.TG =  payload.username 
            headers.tgid =  payload.userid
            //params = params+'&usr='+payload.user
        }
        if (payload && payload.loadphoto) {
            params = params+'&photo='+payload.loadphoto
        }
        
        const res = await Axios.get(RESOURCE_NAME+'/Requests/'+id+params,{
            headers: headers}
          )
        //console.log('res Requests axios: '+JSON.stringify(res))

        return res
    },
    async getInnerDocs(id,payload) {
        //console.log('getInnerDocs axios: ',id,JSON.stringify(payload))
        //var params = '?'
        var headers = {}
        if (payload && payload.username) {
            headers.TG =  payload.username 
            headers.tgid =  payload.userid
        }
        
        const res = await Axios.get(RESOURCE_NAME+'/InnerDocs/'+id,{
            headers: headers}
          )
        return res
    },
    async getTaskList(id,payload) {
        console.log('load task list axios: ',id,JSON.stringify(payload))
        //var params = '?'
        var headers = {}
        if (payload && payload.username) {
            headers.TG =  payload.username 
            headers.tgid =  payload.userid
        }
        
        const res = await Axios.get(RESOURCE_NAME+'/gettasklist/',{
            headers: headers}
          )
        return res
    },
    async getPhoto(id,payload) {
        //console.log('getPhoto axios: ',id)
        var headers = {}
        if (payload && payload.username) {
            headers.TG =  payload.username 
            headers.tgid =  payload.userid
        }
        const res = await Axios.get(RESOURCE_NAME+'/getPhoto/'+id,
            {headers: headers, responseType: 'blob'}
          )
        //console.log('getPhoto axios result: ',JSON.stringify(res))
        return res.data
    },
    async getTask(id,payload) {
        console.log('getTask axios: '+id+', '+JSON.stringify(payload))
        var params = '?'
        var headers = {}
        if (payload && payload.user) {
            headers.TG =  payload.user 
            params = params+'&usr='+payload.user
            headers.tgid =  payload.userid
        }
        const res = await Axios.get(RESOURCE_NAME+'/gettask/'+id+params,{
            headers: headers}
          )
        //console.log('res axios: '+JSON.stringify(res.data))
        let elem = new TaskModel(res.data)
        if (res.data.result != true) {
            elem = {id:'', result: res.data.error}
        }
        return elem
    },    
    async loadCars() {
        const res = await Axios.get(RESOURCE_NAME+'/Cars')
       //console.log('res axios: '+JSON.stringify(res.data))
        return res.data
    },
    async saveRequestConfirmation(id, payload) {
        var headers = {}
        if (payload && payload.username) {
            headers.TG =  payload.username 
            headers.tgid =  payload.userid
        }
        return await Axios.post(RESOURCE_NAME+'/saveRequestConfirmation/'+id, payload,{
            headers: headers});
    },
    async saveInnerDoc(id, data) {
        var headers = {}
        if (data && data.username) {
            headers.TG =  data.username 
            headers.tgid =  data.userid
        }        
        return await Axios.post(RESOURCE_NAME+'/saveInnerDoc/'+id, data,{
            headers: headers});
    },
    async cancelInnerDoc(id, data) {
        var headers = {}
        if (data && data.username) {
            headers.TG =  data.username 
            headers.tgid =  data.userid
        }        
        return await Axios.post(RESOURCE_NAME+'/cancelInnerDoc/'+id, data, {
            headers: headers});
    },
    async saveTask(id, data) {
        var headers = {}
        if (data && data.user) {
            headers.TG =  data.user 
            headers.tgid =  data.userid
        }        
        return await Axios.post(RESOURCE_NAME+'/savetask/'+id, data,{
            headers: headers});
    },
    async saveNewPKO(id, data) {
        return await Axios.post(RESOURCE_NAME+'/saveNewPKO/'+id, data);
    },
    async newRKO(id, tg_id) {
        var headers = {}
        if (tg_id) {
            headers.tgid = tg_id
        }
        const res = await Axios.get(RESOURCE_NAME+'/NewRKO/'+id, { headers: headers})
        console.log('res axios: '+JSON.stringify(res.data))
        //let elem = new DocumentModel(res.data)
        return res.data
    },
    async saveNewRKO(id, data) {
        return await Axios.post(RESOURCE_NAME+'/saveNewRKO/'+id, data);
    },
    async saveNewRKO_other(data) {
        return await Axios.post(RESOURCE_NAME+'/saveNewRKO_other', data);
    },
    async saveArendaOut(id, data) {
        return await Axios.post(RESOURCE_NAME+'/saveArenda/'+id+'/out', data);
    },
    async saveArendaIn(id, data) {
        return await Axios.post(RESOURCE_NAME+'/saveArenda/'+id+'/in', data);
    },
    async saveDiscounts(id, data) {
        return await Axios.post(RESOURCE_NAME+'/saveDiscounts/'+id, data);
    },   
    async printArenda(id, data) {
        return await Axios.post(RESOURCE_NAME+'/printArenda/'+id, data);
    },
    async sendInfoToTelegram(id, data) {
        return await Axios.post(RESOURCE_NAME+'/sendInfo/'+id, data);
    },
    async sendPhoto(id, payload) {
        var params = '?'
        var headers = {}
        if (payload && payload.user) {
            headers.TG =  payload.user 
            headers.tgid =  payload.userid
        }

        return await Axios.post(RESOURCE_NAME+'/savePhoto/'+payload.doctype+'/'+id+params, 
            payload.data,
            { headers: headers}
            );
    },
    update(id, data) {
        return Axios.put(RESOURCE_NAME+'tele/'+id, data);
    },

}