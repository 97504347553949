<template>
    <taskDoc v-if="taskid != '' && !!taskid" ></taskDoc>
    <div class="page-wrapper" id="main" 
         v-show="listvisible">
        <v-row class="ma-3">
            <v-btn
                class="mx-1"
                small
                @click="closeForm()"
                >Закрыть
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
                class="mx-1"
                small icon="mdi-update"
                @click="updateList()"
                >
          </v-btn>          
          <v-icon></v-icon>
        </v-row>
        <v-row class = "ma-3">
            <v-switch
                v-model="showCompleted"
                color="primary"
                hide-details
                inset
                label="Показывать завершенные"
            ></v-switch>
        </v-row>
        <v-row class = "scrollable-content ma-3">
            <v-list select-strategy="classic"
                lines="three"
            >
            <v-list-item
                v-for="item in filteredDocs"
                :key="item.tasknum"
                item-props
                lines="three"
                
                :subtitle="item.damage"
                @click="openDocForm(item.id)"
            >
            <template v-slot:prepend>
                  <v-icon :color="item.completed === true ? 'green' : 'red'" v-if="item.completed">
                              {{ item.completed === true ? 'mdi-check-circle' : 'mdi-alert-circle' }}
                  </v-icon>                                 
            </template>
                <template v-slot:title>
                    <v-row no-gutters>
                        <v-col cols="12">
                            <div align="left" class="text-primary">{{ item.task }} </div>
                            <div class="text-subtitle-1"> {{ item.ts }} </div>
                            <div align="left" class="text-subtitle-3">{{ item.arenda }} </div>
                            <div> ответственный: {{ item.sotr }} </div>
                        </v-col>
                    </v-row>
                </template>
            </v-list-item>
            </v-list>
        </v-row>

    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex"
  import taskDoc from '@/views/form_Task.vue'

  export default {
    components: {taskDoc},
    props: {
      firsttaskid: String
    },
    data: function() {
      return {
        showCompleted: false
     }
    },
    computed: {
      ...mapGetters('doc',['dataLoaded','url_vars','task','taskid','tg','taskList']),
      dataLoading(){
        return this.dataLoaded == false
      },
      filteredDocs() {
        return this.taskList.filter(item => this.showCompleted || item.completed === false)
      },
      listvisible(){
        return this.taskid === '' || !this.taskid
      }
    },
    created: async function() {
        console.log('task list created. init:', this.firsttaskid)
        await this.loadTaskList()
   },
    methods: {
      async loadTaskList() {
        this.$store.commit('doc/dataLoaded', false)
        await this.$store.dispatch('doc/loadTaskList', {})
        if (this.firsttaskid) {
          await this.$store.commit('doc/setTaskid', this.firsttaskid) 
        }   
      },
      openDocForm(id) {
         // Открываем форму передавая id запроса
         console.log('doc ',id)
         this.$store.commit('doc/setTaskid', id) 

      },
      
      async updateList() {  
        await this.loadTaskList()
      },
      async closeForm() {  
        this.TWA.close()
      },

      formatDate(date) {
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()
        return `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`
        }

      }
    }
  
  </script>
  
<style>
.scrollable-content {
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
}
tr {
    white-space: pre-wrap;
}


</style>
